import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { getSession } from './store';
import { useSelector } from 'react-redux';
import LoginForm from './content/pages/Login';
import { connectSIO, disconnectSIO } from './sio';
import {useEffect, useState} from 'react';
import SuspenseLoader from "./components/SuspenseLoader";

function App() {
  const content = useRoutes(router);
  const [envInitialized, setEnvInitialized] = useState(false);

  const session = useSelector(getSession);

  useEffect(() => {
    if (session) {
      connectSIO(session);
    } else {
      disconnectSIO();
    }
  }, [session]);


  useEffect(() => {
    fetch('/env').then(res=> res.ok? res.json(): process.env).then(envData => {
      Object.keys(envData).forEach(k => {
        localStorage.setItem(k, envData[k]);
      })
      setEnvInitialized(true)
    })
  }, []);

  return envInitialized?(
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {session ? content : <LoginForm />}
      </LocalizationProvider>
    </ThemeProvider>
  ): <SuspenseLoader />;
}
export default App;
