import { io } from 'socket.io-client';
import { setSubminds, store } from './store';
import {getEnvironmentVariable} from "./utils/common";

export let socket = null;

export const disconnectSIO = () => {
  console.log('socket io server disconnected');
  socket = null;
};

const initSIO = (socket) => {
  socket.on('connect', onConnect);
  socket.on('disconnect', onDisconnect);
  socket.on('subminds_state', onSubmindsState);
};

const onConnect = () => {
  console.log('socket io connected');
};

const onDisconnect = () => {
  console.log('socket io disconnected');
};

const onSubmindsState = (data) => {
  console.log('received subminds state - ', data);
  store.dispatch(setSubminds(data));
};

export const connectSIO = (session) => {
  if (!socket) {
    socket = io(
        getEnvironmentVariable("REACT_APP_SERVER_URL"),
        {
          extraHeaders: {
            session: session
          }
        }
    );
    initSIO(socket);
  }
};

export const emitBroadcast = (body) => {
  const errMsg = `Failed to emit broadcast message with body=${JSON.stringify(
    body
  )}`;
  if (!socket) {
    console.warn(`${errMsg}: Socket is disconnected`);
  } else {
    if (body?.msg_type) {
      console.log(`emitting broadcast message ${JSON.stringify(body)}`);
      socket.emit('broadcast', body);
    } else {
      console.warn(`${errMsg}: Missing attribute "msg_type"`);
    }
  }
};
